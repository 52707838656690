<template>
  <VDropdown class="d-inline" :hideTriggers="['click', 'focus']" :handleResize="true" :autoHide="false">
    <b-button
      variant="none"
      size="sm"
      class="p-0 toggle-info-btn"
      :tabindex="isEditMode ? '-1' : '0'"
      @keyup.esc="($event) => ($event.target.hasAttribute('data-popper-shown') ? $event.target.click() : $event.preventDefault())"
    >
      <BIconInfoCircle :scale="scale ? scale : 1.2"
    /></b-button>

    <template #popper>
      <div class="custom-scroll" v-html="description"></div>
    </template>
  </VDropdown>
</template>
<script>
import { BIconInfoCircle } from 'bootstrap-vue'
export default {
  name: 'InfoButtonTogglePopover',
  props: ['description', 'scale', 'isEditMode'],
  components: {
    BIconInfoCircle,
  },
}
</script>
