<template>
  <div>
    <template v-if="!data.isFormat">
      <div id="location-html-content" v-if="htmlContent" v-html="htmlContent"></div>
      <p v-else class="theme-font-regular font-16">
        {{ translateLanguage(DISPLAY_MESSAGES.LOCATION_WIDGET_EMPTY) }}
      </p>
    </template>
    <template v-else>
      <p v-if="!data.widgetResult" id="location-format-error-msg" class="theme-font-regular font-16">{{ data.errorMessage }}</p>
      <p v-else-if="!data.widgetResult.formatContent" class="theme-font-regular font-16">
        {{ translateLanguage(DISPLAY_MESSAGES.LOCATION_WIDGET_EMPTY) }}
      </p>
      <template v-else>
        <b-row>
          <b-col>
            <template v-if="data.selectedView == 'form-view'">
              <div class="row mb-2 format-form-view" v-for="(formView, index) in data.widgetResult.formatContent.slice(0, limit)" :key="index">
                <div class="col-12">
                  <label class="mb-0 mr-1 d-inline-flex key-property"
                    ><template v-if="formView.showLink === 0 && formView.url"
                      ><a
                        class="theme-font-bold font-16 location-form-view-key-link"
                        :href="formView.url"
                        v-srOnlyNewWindow
                        target="_blank"
                        v-html="formView.key"
                      ></a>
                    </template>
                    <span v-else v-html="formView.key"></span>
                    <template v-if="formView.value">:</template>
                  </label>
                  <a
                    class="theme-font-bold font-16 location-form-view-value-link"
                    v-if="formView.showLink === 1 && formView.url && formView.value"
                    :href="formView.url"
                    target="_blank"
                    v-srOnlyNewWindow
                    v-html="formView.value"
                  ></a>

                  <span v-else v-html="formView.value"></span>
                  <span class="color-gray mx-1" v-if="formView.value && formView.showMap && formView.y && formView.x">|</span>
                  <a
                    target="_blank"
                    v-srOnlyNewWindow
                    :href="`https://www.google.com/maps/search/?api=1&query=${formView.y},${formView.x}`"
                    v-if="formView.showMap && formView.y && formView.x"
                    class="theme-font-bold font-16 location-form-view-map"
                    >{{ translateLanguage('Lnk_Location_widget_Map') }}</a
                  >
                  <span class="color-gray mx-1" v-if="formView.description">|</span>
                  <InfoButtonTogglePopover class="pl-05" :description="formView.description" v-if="formView.description" />
                </div>
                <div v-if="formView.detail" class="col-12 row-details">
                  <a class="theme-font-bold font-16" v-if="formView.showLink === 2 && formView.url" :href="formView.url" target="_blank" v-srOnlyNewWindow>{{
                    formView.detail
                  }}</a>
                  <span class="color-gray" v-else>{{ formView.detail }}</span>
                </div>
              </div>
            </template>
            <template v-if="data.selectedView == 'row-view'">
              <div
                class="form-row widget-format-view format-row-view pb-2"
                v-for="(rowView, index) in data.widgetResult.formatContent.slice(0, limit)"
                :key="index"
              >
                <div class="col-12 key-property">
                  <a
                    v-if="rowView.showLink === 0 && rowView.url"
                    :href="rowView.url"
                    target="_blank"
                    v-srOnlyNewWindow
                    v-html="rowView.key"
                    class="theme-font-bold font-16 location-row-view-key-link"
                  ></a
                  ><span v-else v-html="rowView.key"></span>
                </div>
                <div class="col-12">
                  <a
                    v-if="rowView.showLink === 1 && rowView.url && rowView.value"
                    :href="rowView.url"
                    target="_blank"
                    v-srOnlyNewWindow
                    class="theme-font-bold font-16 location-row-view-value-link"
                    v-html="rowView.value"
                  ></a>

                  <span v-else v-html="rowView.value"></span>
                  <span class="color-gray mx-1" v-if="rowView.value && rowView.showMap && rowView.y && rowView.x">|</span>
                  <a
                    target="_blank"
                    v-srOnlyNewWindow
                    class="theme-font-bold font-16 location-row-view-map"
                    :href="`https://www.google.com/maps/search/?api=1&query=${rowView.y},${rowView.x}`"
                    v-if="rowView.showMap && rowView.y && rowView.x"
                    >{{ translateLanguage('Lnk_Location_widget_Map') }}</a
                  >
                  <span class="color-gray mx-1" v-if="rowView.description">|</span>
                  <InfoButtonTogglePopover class="pl-05" :description="rowView.description" v-if="rowView.description" />
                </div>
                <div v-if="rowView.detail" class="col-12 row-details">
                  <a class="theme-font-bold font-16" v-if="rowView.showLink === 2 && rowView.url" :href="rowView.url" target="_blank" v-srOnlyNewWindow>{{
                    rowView.detail
                  }}</a>
                  <span class="color-gray" v-else>{{ rowView.detail }}</span>
                </div>
              </div>
            </template>
            <template v-if="data.selectedView == 'table-view'">
              <table class="table table-borderless">
                <tr class="widget-format-view format-table-view" v-for="(tableView, index) in data.widgetResult.formatContent.slice(0, limit)" :key="index">
                  <th scope="row" class="px-0 py-2 font-weight-normal key-property" v-if="!tableView.value" colspan="2">
                    <a
                      class="mr-1 theme-font-bold font-16 location-table-view-key-link"
                      v-if="tableView.showLink === 0 && tableView.url"
                      :href="tableView.url"
                      target="_blank"
                      v-srOnlyNewWindow
                      v-html="tableView.key"
                    ></a
                    ><span class="mr-1" v-else v-html="tableView.key"></span>
                    <a
                      target="_blank"
                      v-srOnlyNewWindow
                      class="theme-font-bold font-16 location-table-view-map"
                      :href="`https://www.google.com/maps/search/?api=1&query=${tableView.y},${tableView.x}`"
                      v-if="tableView.showMap && tableView.y && tableView.x"
                      >{{ translateLanguage('Lnk_Location_widget_Map') }}</a
                    >
                    <span class="color-gray mx-1" v-if="tableView.description">|</span>
                    <InfoButtonTogglePopover class="pl-05" :description="tableView.description" v-if="tableView.description" />
                    <div v-if="tableView.detail" class="row-details">
                      <a
                        class="theme-font-bold font-16"
                        v-if="tableView.showLink === 2 && tableView.url"
                        :href="tableView.url"
                        target="_blank"
                        v-srOnlyNewWindow
                        >{{ tableView.detail }}</a
                      >
                      <span class="color-gray" v-else>{{ tableView.detail }}</span>
                    </div>
                  </th>
                  <template v-else>
                    <th
                      scope="row"
                      :style="{
                        width: `${data.keyWidth}%`,
                      }"
                      class="px-0 py-2 font-weight-normal key-property"
                    >
                      <a
                        v-if="tableView.showLink === 0 && tableView.url"
                        :href="tableView.url"
                        class="theme-font-bold font-16 location-table-view-key-link"
                        target="_blank"
                        v-srOnlyNewWindow
                        v-html="tableView.key"
                      ></a
                      ><span v-else v-html="tableView.key"></span>
                    </th>
                    <td
                      :style="{
                        width: `${data.valueWidth}%`,
                      }"
                      class="px-2 py-2"
                    >
                      <a
                        v-if="tableView.showLink === 1 && tableView.url && tableView.value"
                        :href="tableView.url"
                        class="theme-font-bold font-16 location-table-view-value-link"
                        v-srOnlyNewWindow
                        target="_blank"
                        v-html="tableView.value"
                      ></a>

                      <span v-else v-html="tableView.value"></span>
                      <span class="color-gray mx-1" v-if="tableView.value && tableView.showMap && tableView.y && tableView.x">|</span>
                      <a
                        target="_blank"
                        v-srOnlyNewWindow
                        class="theme-font-bold font-16 location-table-view-map"
                        :href="`https://www.google.com/maps/search/?api=1&query=${tableView.y},${tableView.x}`"
                        v-if="tableView.showMap && tableView.y && tableView.x"
                        >{{ translateLanguage('Lnk_Location_widget_Map') }}</a
                      >
                      <span class="color-gray mx-1" v-if="tableView.description">|</span>
                      <InfoButtonTogglePopover class="pl-05" :description="tableView.description" v-if="tableView.description" />
                      <div v-if="tableView.detail" class="row-details">
                        <a
                          class="theme-font-bold font-16"
                          v-if="tableView.showLink === 2 && tableView.url"
                          :href="tableView.url"
                          target="_blank"
                          v-srOnlyNewWindow
                          >{{ tableView.detail }}</a
                        >
                        <span class="color-gray" v-else>{{ tableView.detail }}</span>
                      </div>
                    </td>
                  </template>
                </tr>
              </table>
            </template>
          </b-col>
          <b-col cols="12" sm="12" md="6" class="chart-area" v-if="chartContent && chartContent.length">
            <Charts :chartContent="chartContent" />
          </b-col>
        </b-row>
        <b-row v-if="tableContent && tableContent.length">
          <b-col>
            <Tables :tableContent="tableContent" />
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>
<script>
import { DISPLAY_MESSAGES } from '../../../utilities/constants'
import InfoButtonTogglePopover from '../../common/InfoButtonTogglePopover.vue'
export default {
  name: 'LocationWidgetContent',
  data() {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InfoButtonTogglePopover,
    Charts: () => import('../../../components/charts/Charts.vue'),
    Tables: () => import('../../../components/tables/Tables.vue'),
  },
  computed: {
    htmlContent() {
      return this.data.widgetResult
        ? this.isExpandedView
          ? this.data.widgetResult.htmlExpandedContent
          : this.data.widgetResult.htmlContent
        : this.data.errorMessage
    },
    limit() {
      return this.isExpandedView ? undefined : this.data.dashboardItemCnt
    },
    chartContent() {
      return this.isExpandedView ? this.data.chartDashboardContents : []
    },
    tableContent() {
      return this.isExpandedView ? this.data.wdgtTblDashboardContent : []
    },
  },
}
</script>
<style scoped lang="scss">
.row-details {
  margin-top: -0.25rem;
}
.chart-area {
  padding: 20px 0px;
}
</style>
